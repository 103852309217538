<script setup lang="ts">
import MenuComponent from '@/components/menu/MenuComponent.vue'
import { SettingsKeys } from '@/enums'
import { useSettingsStore } from '@/stores/settings'
import { useUserStore } from '@/stores/user'
import { ref, watch } from 'vue'
import { RouterView } from 'vue-router'
import { toast } from 'vue3-toastify'

const userStore = useUserStore()
const settingsStore = useSettingsStore()
const user = ref(userStore.user)
const loaded = ref(!!user.value)
const collapsed = ref(false)

watch(user, () => {
  console.log('user')
  if (user.value) {
    loaded.value = true
  }
})

if (!settingsStore.getSettings(SettingsKeys.DefaultLocation)) {
  toast.warn('Please set default location in settings')
}
</script>

<template>
  <div
    class="z-10 hidden overflow-x-hidden transition-all desktop-menu lg:fixed lg:inset-y-0 lg:flex lg:flex-col"
    :class="[collapsed ? 'lg:w-14' : 'lg:w-64']"
  >
    <MenuComponent @collapsed="(val) => (collapsed = val)" />
  </div>
  <div id="main_menu" class="w-full menu lg:hidden">
    <MenuComponent @collapsed="(val) => (collapsed = val)" />
  </div>
  <div class="flex flex-col flex-1 transition-all" :class="[collapsed ? 'lg:pl-14' : 'lg:pl-64']">
    <div class="min-h-screen">
      <RouterView />
    </div>
  </div>
</template>

<style scoped></style>
