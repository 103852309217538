import { defineStore } from 'pinia'

export const useSettingsStore = defineStore('settings', () => {
  function getSettings(key: string | null = null, defaultValue: string | null = null) {
    try {
      const s = window.localStorage.getItem('slgr_settings')
      const settings = s === null ? {} : JSON.parse(s)
      if (key) {
        if (settings[key] === undefined) {
          return defaultValue
        }
        return settings[key]
      }
      return settings
    } catch (err) {
      console.log(err)
      return defaultValue
    }
  }
  function saveSetting(key: string, value: number | string) {
    try {
      const settings = getSettings()
      settings[key] = value

      window.localStorage.setItem('slgr_settings', JSON.stringify(settings))
    } catch (err) {
      console.log(err)
    }
  }

  return { getSettings, saveSetting }
})
