<template>
  <div
    ref="selectRef"
    class="relative p-0 border rounded-md focus-within:z-20 border-opacity-20 group"
    :class="[
      invalid ? 'bg-error/20 border-error outline outline-error' : 'bg-inputs border-zinc-100',
      disabled ? 'bg-gray-700/5 opacity-50 cursor-not-allowed' : '',
    ]"
  >
    <label
      :for="formname"
      class="text-xs font-semibold flex justify-between absolute -top-[10px] left-[7px] rounded-sm px-1 z-10"
      :class="[
        disabled ? '!text-gray-400' : '',
        invalid ? 'text-red-200 invalid' : 'text-zinc-300',
      ]"
    >
      <span>
        {{ label }} {{ invalid }}
        <span v-if="required && !disabled" class="text-error">*</span>
      </span>
      <div
        v-if="badge"
        class="bg-secondary/75 text-white rounded-full text-xs font-semibold h-4 ml-2 px-1.5 whitespace-nowrap"
      >
        {{ badge }}
      </div>
      <div
        v-if="optionalBadge"
        class="bg-info/75 text-dark rounded-full text-xs font-semibold h-4 ml-2 px-1.5 whitespace-nowrap"
      >
        Optional
      </div>

      <svg
        v-if="tooltip"
        v-tooltip="{
          content: tooltip,
          html: true,
          placement: 'top-end',
          skidding: 16,
        }"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        fill="currentColor"
        class="flex-shrink-0 w-5 h-5 ml-1 -mr-2 rounded-full text-zinc-400 cursor-help"
      >
        <path
          fill-rule="evenodd"
          d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
          clip-rule="evenodd"
        ></path>
      </svg>
    </label>
    <div class="relative">
      <!-- Multiple selection with search and tags -->
      <div
        v-if="multiple && searchable"
        class="flex flex-wrap items-center cursor-text min-h-10"
        @click="focusInput"
      >
        <span
          v-for="(item, index) in selectedItems"
          :key="'tag-' + index"
          class="flex items-center px-2 py-1 m-1 text-xs text-white rounded-full"
          :style="{ 'background-color': item.color ?? '#238fdb' }"
        >
          {{ item.label }}
          <XMarkIcon class="w-4 h-4 ml-1 cursor-pointer" @click.stop="removeSelectedItem(item)" />
        </span>
        <input
          v-model="fuzzyValue"
          @click.stop="toggleList"
          @focus="openList"
          @input="filterList"
          @keydown.down.prevent="onArrowDown"
          @keydown.up.prevent="onArrowUp"
          @keydown.enter.prevent="onEnter"
          @keydown.tab="onTab"
          type="text"
          :placeholder="placeholder ?? 'Search...'"
          :disabled="disabled"
          tabindex="0"
          class="flex-1 text-xs text-white bg-transparent outline-none placeholder-zinc-300/40"
          autocomplete="off"
          ref="inputRef"
        />
      </div>
      <!-- Multiple selection without search -->
      <div
        v-else-if="multiple && !searchable"
        @click="toggleList"
        @focus="openList"
        @keydown.down.prevent="onArrowDown"
        @keydown.up.prevent="onArrowUp"
        @keydown.enter.prevent="onEnter"
        @keydown.tab="onTab"
        :tabindex="disabled ? -1 : 0"
        class="cursor-pointer"
      >
        <input
          type="text"
          :id="formname"
          :name="formname"
          :placeholder="placeholder ?? 'Select options...'"
          :disabled="disabled"
          tabindex="-1"
          readonly
          :value="itemSelected"
          class="text-white bg-transparent placeholder-zinc-300/40"
        />
      </div>
      <!-- Single selection with search -->
      <input
        v-else-if="!multiple && searchable"
        v-model="fuzzyValue"
        @click="toggleList"
        @focus="openList"
        @input="filterList"
        @keydown.down.prevent="onArrowDown"
        @keydown.up.prevent="onArrowUp"
        @keydown.enter.prevent="onEnter"
        @keydown.tab="onTab"
        type="text"
        :id="formname"
        :name="formname"
        :placeholder="placeholder ?? 'Search...'"
        :disabled="disabled"
        tabindex="0"
        class="text-white bg-transparent placeholder-zinc-300/40 cursor-text"
        autocomplete="off"
      />
      <!-- Single selection without search -->
      <input
        v-else
        @click="toggleList"
        @focus="openList"
        @keydown.down.prevent="onArrowDown"
        @keydown.up.prevent="onArrowUp"
        @keydown.enter.prevent="onEnter"
        @keydown.tab="onTab"
        type="text"
        :id="formname"
        :name="formname"
        :placeholder="placeholder ?? 'Select an option...'"
        :disabled="disabled"
        tabindex="0"
        readonly
        :value="itemSelected"
        class="text-white bg-transparent cursor-pointer placeholder-zinc-300/40"
      />
      <XMarkIcon
        class="absolute z-0 top-[11px] right-6 !w-5 !h-5 text-zinc-300 cursor-pointer hover:text-white transition-all"
        :class="{ 'z-10': expand }"
        @click="clear"
        v-if="
          (multiple ? modelValue && modelValue.length > 0 : modelValue) &&
          !disabled &&
          !(multiple && searchable)
        "
      />
      <ChevronUpDownIcon
        class="absolute z-0 top-[11px] right-1 !w-5 !h-5 text-zinc-300 cursor-pointer hover:text-white transition-all"
        :class="{ 'z-10': expand }"
        @click="toggleList"
        v-if="!disabled"
      />

      <!-- Items dropdown -->
      <ul
        v-if="expand"
        class="absolute left-0 right-0 z-50 py-1 mt-1 overflow-auto text-base rounded-md shadow-lg max-h-64 bg-zinc-700 ring-1 ring-dark ring-opacity-5 focus:outline-none sm:text-sm"
        tabindex="-1"
        role="listbox"
        aria-labelledby="listbox-label"
      >
        <li
          v-for="(item, index) in localList"
          :key="'list-' + formname + '-' + index"
          @click="updateSelected(item)"
          @mouseenter="arrowCounter = index"
          :id="'listbox-option-' + item.id"
          role="option"
          :class="[
            index === arrowCounter ? 'bg-primary/20' : 'bg-transparent',
            item.color ? 'py-1' : 'py-2',
          ]"
          class="relative pl-3 cursor-pointer select-none text-zinc-100 pr-9 group"
        >
          <span
            v-if="multiple ? modelValue.includes(item.value) : item.value === modelValue"
            class="absolute inset-y-0 left-0 flex items-center pl-3"
          >
            <CheckIcon class="!w-5 !h-5 text-primary" />
          </span>
          <span v-if="item.desc">
            <span
              :class="
                (multiple ? modelValue.includes(item.value) : item.value === modelValue)
                  ? 'font-bold text-primary'
                  : 'font-normal'
              "
              class="block truncate"
            >
              {{ item.label }}
              <span v-if="item.label_insider_tag">({{ item.label_insider_tag }})</span>
            </span>
            <span
              :class="
                (multiple ? modelValue.includes(item.value) : item.value === modelValue)
                  ? 'font-semibold'
                  : 'font-light'
              "
              class="block truncate"
            >
              {{ item.desc }}
            </span>
          </span>
          <span v-else-if="item.color" class="block truncate pl-7">
            <div
              class="px-2 py-1 text-xs font-bold text-white rounded-md w-min whitespace-nowrap"
              :style="{ 'background-color': item.color }"
            >
              {{ item.label }}
              <span v-if="item.label_insider_tag">({{ item.label_insider_tag }})</span>
            </div>
          </span>
          <span
            v-else
            :class="
              (multiple ? modelValue.includes(item.value) : item.value === modelValue)
                ? 'font-bold text-primary'
                : 'font-normal'
            "
            class="block pl-6 truncate"
          >
            {{ item.label }}
            <span v-if="item.label_insider_tag">({{ item.label_insider_tag }})</span>
          </span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref, computed, watch, onMounted, defineProps, defineEmits, withDefaults } from 'vue'
import { onClickOutside } from '@vueuse/core'
import Fuse from 'fuse.js'
import { CheckIcon, ChevronUpDownIcon, XMarkIcon } from '@heroicons/vue/24/outline'

export interface Item {
  id?: string | number
  value: any
  label: string
  desc?: string
  color?: string
  label_insider_tag?: string
}

const props = withDefaults(
  defineProps<{
    modelValue: any
    items: Item[]
    formname: string
    label?: string
    nolabel?: boolean
    required?: boolean
    disabled?: boolean
    searchable?: boolean
    invalid?: string
    badge?: string | null
    tooltip?: string | null
    optionalBadge?: boolean
    placeholder?: string
    multiple?: boolean
  }>(),
  {
    multiple: false,
  },
)

const emit = defineEmits(['update:modelValue', 'changed'])

const arrowCounter = ref(-1)
const expand = ref(false)
const block = ref(false)
const localList = ref<Item[]>([])
const fuzzyModel = ref<Fuse<Item> | null>(null)
const fuzzyValue = ref<string>('')

const selectedItems = computed(() => {
  if (Array.isArray(props.modelValue)) {
    return props.modelValue
      .map((value) => localList.value.find((item) => item.value === value))
      .filter(Boolean) // Remove any undefined values
  } else {
    return []
  }
})

const itemSelected = computed(() => {
  if (props.modelValue === null || localList.value.length === 0) {
    return 'Select an option...'
  }
  if (props.multiple) {
    if (Array.isArray(props.modelValue) && props.modelValue.length > 0) {
      const selectedItems = localList.value.filter((i) => props.modelValue.includes(i.value))
      return selectedItems.map((i) => i.label).join(', ')
    } else {
      return 'Select options...'
    }
  } else {
    const selectedItem = localList.value.find((i) => i.value === props.modelValue)
    return selectedItem ? selectedItem.label : 'Select an option...'
  }
})

watch(
  () => props.items,
  () => {
    loadFuzzy()
  },
  { deep: true, immediate: true },
)

onMounted(() => {
  loadFuzzy()
})

function loadFuzzy() {
  localList.value = props.items
  if (props.searchable) {
    fuzzyModel.value = new Fuse(props.items, {
      minMatchCharLength: 2,
      threshold: 0.6,
      ignoreLocation: true,
      keys: ['label'],
    })
  }
  if (!props.multiple) {
    fuzzyValue.value = props.items.find((i) => i.value === props.modelValue)?.label ?? ''
  } else {
    fuzzyValue.value = ''
  }
}

function updateSelected(item: Item) {
  if (props.disabled) {
    return
  }
  if (props.multiple) {
    let newValue = []
    if (Array.isArray(props.modelValue)) {
      if (props.modelValue.includes(item.value)) {
        // Remove item if already selected
        newValue = props.modelValue.filter((v) => v !== item.value)
      } else {
        // Add item if not selected
        newValue = [...props.modelValue, item.value]
      }
    } else {
      newValue = [item.value]
    }
    emit('update:modelValue', newValue)
    emit('changed', newValue)
    // Keep dropdown open for multiple selection
  } else {
    if (item.value === props.modelValue) {
      expand.value = false
    }
    fuzzyValue.value = item.label
    emit('update:modelValue', item.value)
    emit('changed', item.value)
    away()
  }
}

function removeSelectedItem(item: Item) {
  if (props.disabled) {
    return
  }
  if (Array.isArray(props.modelValue)) {
    const newValue = props.modelValue.filter((v) => v !== item.value)
    emit('update:modelValue', newValue)
    emit('changed', newValue)
  }
}

function onArrowDown() {
  if (arrowCounter.value < localList.value.length - 1) {
    arrowCounter.value += 1
  }
}

function onArrowUp() {
  if (arrowCounter.value > 0) {
    arrowCounter.value -= 1
  }
}

function onEnter(e: KeyboardEvent) {
  if (props.disabled) {
    return
  }
  e.preventDefault()
  if (localList.value[arrowCounter.value]) {
    const selectedItem = localList.value[arrowCounter.value]
    if (props.multiple) {
      updateSelected(selectedItem)
      // reset fuzzy
      fuzzyValue.value = ''
    } else {
      fuzzyValue.value = selectedItem.label
      emit('update:modelValue', selectedItem.value)
      emit('changed', selectedItem.value)
      away()
    }

    filterList()
  }
}

function clear() {
  if (props.disabled) {
    return
  }
  if (props.multiple) {
    emit('update:modelValue', [])
    emit('changed', [])
  } else {
    emit('update:modelValue', null)
    emit('changed', null)
    fuzzyValue.value = ''
  }
}

function onTab() {
  away()
}

function filterList() {
  expand.value = true
  if (fuzzyValue.value && fuzzyModel.value) {
    localList.value = fuzzyModel.value.search(fuzzyValue.value).map((i) => i.item)
  } else {
    localList.value = props.items
  }
}

function openList() {
  block.value = true
  setTimeout(() => {
    block.value = false
  }, 250)

  arrowCounter.value = 0
  expand.value = true
}

function away() {
  arrowCounter.value = -1
  expand.value = false
}

function toggleList() {
  if (expand.value && !block.value) {
    away()
  } else {
    openList()
  }
}

function focusInput() {
  inputRef.value?.focus()
}

const inputRef = ref<HTMLInputElement | null>(null)

const selectRef = ref(null)
onClickOutside(selectRef, () => {
  away()
})
</script>

<style scoped>
label {
  background: inherit;
}
label.invalid {
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#2e2e34+0,2e2e34+50,3d3d42+51,3d3d42+100 */
  background: linear-gradient(
    to bottom,
    #2e2e34 0%,
    #2e2e34 56%,
    #513531 57%,
    #513531 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}
input[type='text'] {
  @apply rounded-[5px] block w-full border-0 pt-[10px] py-2 text-white placeholder:italic placeholder-zinc-300/40 bg-transparent;
  @apply focus:ring-2 focus:ring-zinc-300;
  @apply cursor-default;
}
</style>
