<template>
  <transition name="fade" mode="out-in">
    <div
      v-if="localModelValue"
      class="relative"
      style="z-index: 100"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div class="fixed inset-0 transition-opacity bg-opacity-75 bg-zinc-500"></div>

      <div class="fixed inset-0 z-10 overflow-y-auto">
        <div
          :id="'modalbg-' + innerId"
          class="flex items-end justify-center min-h-full text-center sm:p-4"
          :class="modalClasses"
        >
          <div
            class="relative px-4 pt-5 pb-4 text-left transition-all transform rounded-lg shadow-xl bg-page sm:p-6 sm:my-8"
            :class="modalSizeClass"
          >
            <button
              v-if="false"
              type="button"
              @click="close"
              class="absolute block transition-colors ease-in-out rounded-full cursor-pointer top-5 right-5"
            >
              <XCircleIcon
                class="w-10 h-10 -m-0.5 text-error hover:text-darksalmon transition-all"
              />
            </button>

            <div class="mt-3 sm:mt-0">
              <h3
                class="pb-6 text-xl font-bold leading-6 text-center text-white"
                v-html="innerTitle"
              ></h3>
              <slot>
                <p class="text-sm text-gray-500">
                  Lorem ipsum, dolor sit amet consectetur adipisicing elit. Eius aliquam laudantium
                  explicabo pariatur iste dolorem animi vitae error totam. At sapiente aliquam
                  accusamus facere veritatis.
                </p>
              </slot>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script lang="ts">
import { defineComponent, ref, computed, onMounted, onBeforeUnmount, PropType, watch } from 'vue'
import { XCircleIcon } from '@heroicons/vue/24/solid'

export default defineComponent({
  name: 'Modal',
  components: {
    XCircleIcon,
  },
  props: {
    modelValue: {
      type: [String, Boolean, Number] as PropType<string | boolean | number>,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    clickaway: {
      type: Boolean,
      default: true,
    },
    big: {
      type: Boolean,
      default: false,
    },
    xl: {
      type: Boolean,
      default: false,
    },
    fullwidth: {
      type: Boolean,
      default: false,
    },
    up: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['close'],
  setup(props, { emit }) {
    const innerTitle = ref<string | null>(props.title)
    const innerId = ref<string>(generateId())

    const localModelValue = computed({
      get: () => props.modelValue,
      set: (value) => {},
    })

    function dec2hex(dec: number) {
      return dec.toString(16).padStart(2, '0')
    }

    function generateId(len = 40): string {
      const arr = new Uint8Array(len / 2)
      window.crypto.getRandomValues(arr)
      return Array.from(arr, dec2hex).join('')
    }

    function close() {
      emit('update:modelValue', false)
      emit('close', { title: innerTitle.value })
    }

    function handleClickOutside(e: MouseEvent) {
      const target = e.target as HTMLElement
      if (target.id === `modalbg-${innerId.value}` && props.clickaway) {
        close()
      }
    }

    function handleKeyDown(e: KeyboardEvent) {
      if (e.key === 'Escape') {
        close()
      }
    }

    const modalClasses = computed(() =>
      props.up ? 'sm:items-start sm:pt-12' : 'sm:items-center lg:pt-12 pt-28 pb-32',
    )

    const modalSizeClass = computed(() => {
      if (props.fullwidth) return 'w-full'
      if (props.xl) return 'sm:max-w-4xl w-full'
      if (props.big) return 'sm:max-w-2xl w-full'
      return 'sm:max-w-lg w-full'
    })

    onMounted(() => {
      document.addEventListener('mousedown', handleClickOutside)
      document.addEventListener('keydown', handleKeyDown)
    })

    onBeforeUnmount(() => {
      document.removeEventListener('mousedown', handleClickOutside)
      document.removeEventListener('keydown', handleKeyDown)
    })

    watch(
      props,
      () => {
        innerTitle.value = props.title
      },
      { deep: true },
    )

    return {
      innerTitle,
      innerId,
      close,
      modalClasses,
      modalSizeClass,
      localModelValue,
    }
  },
})
</script>
